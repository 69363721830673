import { Component, inject, OnInit } from '@angular/core';
import { ServiceCardConfig } from '@app/services/types/service-type';
import { serviceCardBoilerMaintenance, serviceCardTelecom } from '@app/services/constants/services.constants';
import { ThanksContainer } from '@app/services-components/thanks/thanks.container';
import { Store } from '@ngrx/store';
import { selectTransactionInfo } from '@app/services/state/services.selectors';
import { map, take } from 'rxjs/operators';
import { EnergyType } from '@shared/move/enums/energy-type.enum';

@Component({
    selector: 'app-energy-start-thanks-container',
    template: ` <app-thanks-container [upsellServices]="[upsellService]"></app-thanks-container> `,
    standalone: true,
    imports: [ThanksContainer],
})
export class EnergyStartThanksContainer implements OnInit {
    private readonly store = inject(Store);

    private readonly energyOfferSelectionHasGas$ = this.store.select(selectTransactionInfo).pipe(
        map((transaction) => {
            return [EnergyType.Both, EnergyType.Gas].includes(transaction?.energyOffer?.energyOfferSelection?.type);
        })
    );

    public upsellService: ServiceCardConfig = null;

    public ngOnInit(): void {
        this.energyOfferSelectionHasGas$.pipe(take(1)).subscribe((energyOfferSelectionHasGas) => {
            if (energyOfferSelectionHasGas) {
                this.upsellService = serviceCardBoilerMaintenance;
            } else {
                this.upsellService = serviceCardTelecom;
            }
        });
    }
}
