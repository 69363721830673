import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/guards/authentication/authentication.guard';
import { NavigationData } from '@smooved/core';
import { ConfirmEnergySuccessContainer } from './containers/confirm-energy-success/confirm-energy-success.container';
import { ConfirmEnergyContainer } from './containers/confirm-energy/confirm-energy.container';
import { ConfirmContainer } from './containers/confirm/confirm.container';
import { MoveDetailsContainer } from './containers/details/move-details.container';
import { routingDataPreviousStep, routingDataServiceType } from '@app/services/constants/services.constants';
import { ServiceType } from '@app/services/types/service-type';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { EnergyStartThanksContainer } from '@app/services/energy-start/containers/thanks/energy-start-thanks.container';

const routes: Routes = [
    {
        path: 'confirm/:id',
        component: ConfirmContainer,
    },
    {
        path: 'details',
        canActivate: [AuthenticationGuard],
        component: MoveDetailsContainer,
        data: {
            [routingDataPreviousStep]: `/${Navigation.Dashboard}`,
        },
    },
    {
        path: 'confirm-energy',
        data: {
            [NavigationData.CoBranding]: true,
        },
        children: [
            {
                path: '',
                component: ConfirmEnergyContainer,
            },
            {
                path: 'success',
                component: ConfirmEnergySuccessContainer,
            },
            {
                path: 'energy-success',
                component: EnergyStartThanksContainer,
                data: {
                    [routingDataServiceType]: ServiceType.EnergyNewContract,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MoveRoutingModule {}
